export const FORMAT_DATE_PICKER = "YYYY-MM-DD";
export const FORMAT_DATE_TIME = "YYYY-MM-DD H:mm:ss";
export const FORMAT_DATE = "DD MMM YY";
export const FORMAT_TIME_A = "H:mm";
export const FORMAT_DAY = "dddd";
export const FORMAT_DATE_EMPTY = "---/--/----";
export const FORMAT_TIME_EMPTY = "--:-- -- --";
export const FORMAT_TIME = "H:mm:ss";
export const hosts = [
	"https://ridesandbox.shuttlecentral.com",
	"https://ride.shuttlecentral.com",
	"http://localhost:8080",
	"http://127.0.0.1:8080"
];
export const routeBase = ["checkin", "tracking"];

const modelNewRoom = {
	clientNewMessage: false,
	driverNewMessage: false,
	members: { client: 0, driver: 0 },
	lastUpdated: null,
	room: "",
	client: { firstName: "", lastName: "" },
	serviceNumber: "",
	createdAt: null,
	clientToken: ""
};
class newRoomModel {
	constructor() {
		Object.assign(this, modelNewRoom);
	}
}
export { newRoomModel };

export const currencyOptions = [
	{
		value: "USD",
		icon: require("@/assets/countries/usa.png")
	},
	{ value: "MXN", icon: require("@/assets/countries/mex.png") }
];


export const disabledChannelIds = [
  2, 4, 6, 11, 17, 26, 37, 45, 46, 47, 51, 92, 121, 139, 186, 239, 294, 338
]
