<template>
  <v-card
    :color="color"
    :elevation="elevation"
    width="100%"
    class="rounded-t-lg"
    @click="$emit('click')"
  >
    <v-row dense>
      <v-col class="d-flex flex-row">
        <v-icon class="mx-2 material-icons-outlined" :color="iconColor"
          >airplane_ticket</v-icon
        >
        <v-card-text class="ml-n3 my-n3">
          <div class="primary--text body-2">
            {{$t('section.flight.form.flightNumber')}}
          </div>
          <div class="body-2 font-weight-medium">
            {{flightNumber}}
          </div>
        </v-card-text>
      </v-col>
      <v-col class="d-flex flex-row">
        <v-icon class="mx-2 material-icons-outlined" :color="iconColor"
          >schedule</v-icon
        >
        <v-card-text class="ml-n3 my-n3">
          <div class="primary--text body-2">
            {{$t('section.flight.form.flightTime')}}
          </div>
          <div class="body-2 font-weight-medium">
              {{ dateFormat(flightTime) }}
              {{ timeFormat(flightTime) }} hrs
          </div>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import dayjs from "dayjs";
import { FORMAT_DATE, FORMAT_TIME_A } from "@/helpers/constants";
export default {
  props: {
    flightTime:String,
    flightNumber:String,
    elevation: {
      type: String,
      default: "4"
    },
    color: String,
    iconColor: {
      type: String,
      default: "secondary"
    }
  },
  computed: {
    // dateFormated() {
    //  if (!this.date) return "";
    //  return dayjs(this.date).format(FORMAT_DATE);
    // },
    // timeFormated() {
    //  if (!this.date) return "";
    //  return dayjs(this.date).format(FORMAT_TIME_A);
    // }
  },
  methods : {
    dateFormat(date){
      if (!date) return "";
      return dayjs(date).format(FORMAT_DATE);
    },
    timeFormat(date){
      if (!date) return "";
      return dayjs(date).format(FORMAT_TIME_A);
    },
  }
};
</script>
