<template>
  <div>
    <div
        data-grx-placement
        :data-grx-step="step"
        :data-grx-display-type="displayType"
        class="banner1"
    >
    </div>
  </div>
</template>
<script>
export default {
  props: {
    step: String,
    displayType: String,
  },
  components: {
  },
  computed: {
  },
  methods: {
  },
  mounted() {
  }
};
</script>
<style>
  .banner1{
    margin-top: 20px;
    width: 100%;
    /*position: absolute;
    right: 2%;
    top: 300px;*/
  }
</style>
