<template>
	<v-app>
		<v-app-bar v-if="isIOS" app color="white" flat dense></v-app-bar>
		<v-overlay :opacity="0.7" :value="loading || $root.loading">
			<v-progress-circular
				class="overlay-loader"
				indeterminate
				color="primary"
				:width="10"
				size="70"
			></v-progress-circular>
		</v-overlay>
		<v-main style="z-index: 0;" class="fill-height background-first">
			<router-view />
		</v-main>
		<DialogLocked v-if="locked" v-model="locked" />
		<DialogNotification />
	</v-app>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import DialogLocked from "@/components/dialog/DialogLocked";
import DialogNotification from "@/components/dialog/DialogNotification";
import { App as CapacitorApp } from "@capacitor/app";
import { getPath } from "@/helpers/utils";
import chatHelper from "@/service/helpers-chat";

export default {
	components: {
		DialogLocked,
		DialogNotification
	},
	data: () => ({
		locked: false
	}),
	mounted() {
		chatHelper.start();
		CapacitorApp.addListener("backButton", ({ canGoBack }) => {
			if (!canGoBack) {
				CapacitorApp.exitApp();
			} else {
				window.history.back();
			}
		});
		CapacitorApp.addListener("appUrlOpen", event => {
			const url = event.url;
			try {
				let { path, serviceId, token } = getPath(url);
				if (path) {
					setTimeout(() => {
						this._login({ path, serviceId, token }).then(() =>
							this.$router.push(path)
						);
					}, 1500);
				}
			} catch (error) {
				alert("Error appUrlOpen");
			}
		});
	},
	computed: {
		...mapState("global", ["loading"]),
		...mapGetters("global", ["isIOS"]),
		isLogin() {
			return this.$route.path === '/login'
		}
	},
	methods: {
		...mapActions("auth", ["_login"])
	}
};
</script>
<style></style>
<style lang="scss" scoped>
.overlay-loader {
	position: relative;
	z-index: 999;
}
</style>
<style>
.v-app-bar-title__content {
	visibility: hidden;
}
body {
	width: 100%;
}
.container {
  max-width: 700px !important;
}
</style>
