<template>
	<div class="fill-height">
    <section class="text-center chat-powered-by d-flex justify-center" >
      <small>Powered by Shuttle Central</small>
    </section>
    <GoogleTranslatorWidget></GoogleTranslatorWidget>
		<chat-window
      class="fill-height"
			height="100vh"
			ref="chat"
			:current-user-id="currentSenderId"
			:rooms="rooms"
			:messages-loaded="messagesLoaded"
			:messages="messagesForChat"
			:single-room="true"
			:room-id="1"
			:rooms-loaded="true"
			:room-loaded="true"
			:message-actions="[]"
			:show-files="false"
			:show-audio="false"
			:show-reaction-emojis="false"
			:show-footer="showFooter"
			@send-message="sendMessage"
			@fetch-messages="fetchMessages"
			@typing-message="writing"
		>
			<template v-slot:room-header="{}">
				<v-btn text @click="back()"
					><v-icon style="border: solid white;"
						>arrow_back</v-icon
					></v-btn
				>
				{{ driverFullName }}

			</template>
		</chat-window>
	</div>
</template>

<script>
import { auth, firestore, firebase } from "@/plugins/firebase";
import ChatWindow from "vue-advanced-chat";
import GoogleTranslatorWidget from "./GoogleTranslatorWidget";
import dayjs from "dayjs";
import "vue-advanced-chat/dist/vue-advanced-chat.css";
import { api } from "@/service/api.service";
import { initialState } from "@/store/modules/service/constants";
import { mapState } from "vuex";
const modelForMessage = {
	_id: -1,
	content: "",
	senderId: -1,
	username: "",
	avatar: "",
	date: "",
	timestamp: "",
	system: false,
	saved: false,
	distributed: false,
	disableActions: false,
	disableReactions: false,
	seen: false
};

const modelForMessageToSend = {
	id: 0,
	seen: false,
	createdAt: "",
	content: 1,
	userType: "",
	userId: -1
};

const roomsList = [
	{
		roomId: 1,
		roomName: "1",
		avatar: "assets/imgs/people.png",
		index: 1,
		users: []
	}
];

const modelNewRoom = {
	clientNewMessage: false,
	driverNewMessage: false,
	members: { client: 0, driver: 0 },
	lastUpdated: null,
	room: "",
	createdAt: null
};

const modelUpdateRoom = {
	lastUpdated: null
};

export default {
	name: "ChatTraveler",
	props: {
		transportService: {
			required: false,
			default: function() {
				return new initialState();
			}
		},
		roomName: {
			required: true,
			default: -1
		}
	},
	components: { ChatWindow, GoogleTranslatorWidget },
	data() {
		return {
			formats: {
				time: "HH:mm",
				date: "dddd, D MMM YYYY"
			},
			iconStyle: {
				borderWidth: "0 5px 5px 0",
				display: "inline-block",
				padding: "3px"
			},
			messagesForChat: [],
			messages: [],
			messagesLoaded: false,
			currentService: -1,
			rooms: [...roomsList],
			db: {
				room: null
			},
			me: "",
			fcmTokens: { driver: null, client: null },
			listener: null,
			listenerForToken: null,
			userType: "client",
			loaded: false,
			messagesVisible: 20,
			referenceContainer: null
		};
	},

	methods: {
		goToBottom() {
			this.referenceContainer.scrollTo(
				0,
				this.referenceContainer.scrollHeight
			);
		},
		writing() {
			if (this.messagesForChat.length > this.messagesVisible) {
				this.messagesForChat = [
					...this.messages.slice(-this.messagesVisible)
				];
			}
			this.goToBottom();
		},
		fetchMessages({ options }) {
			//
			let opening = options?.reset ?? false; //si esta abriendo el room
			this.messagesLoaded = false;
			this.messagesForChat = [];
			if (opening) {
				this.takeMessagesVisibles();
			} else {
				//scrolling up (quieren ver todos los mensajes)
				this.messagesForChat = [...this.messages]; //se muestran todos los mensajes
				setTimeout(() => {
					this.messagesLoaded = true;
				});
			}
		},
		takeMessagesVisibles() {
			let copy = [...this.messages];
			this.messagesForChat = [...copy.slice(-this.messagesVisible)];
		},
		canBeWritten() {
			return (
				this.userId >= 0 &&
				this.userType != "" &&
				this.transportService.driver.id > 0
			);
		},
		back() {
			this.$emit("back");
		},
		getDriverToken() {
			this.listenerForToken = firestore
				.collection("drivers")
				.doc(String(this.transportService.driver.id))
				.onSnapshot(_docDriver => {
					if (_docDriver.exists) {
						let token = _docDriver.data().token;
						if (token && token != this.fcmTokens.driver) {
							this.fcmTokens.driver = token;
						}
					}
				});
		},
		sendPushNotification(messageNode) {
      let send = true;
			if ((this.fcmTokens.driver ?? null !== null) || send) {
				let data = {
					serviceId: this.transportService.id,
					app: "traveler",
					title: this.myself.firstName + ":",
					text: messageNode.content
				};
				api.post(
					"travelers/notifications/send/" + this.fcmTokens.driver,
					{
						data,
						skipLoading: true
					}
				);
			}
		},
		sendMessage(messageNode) {
			const servertTimeStamp = firebase.firestore.FieldValue.serverTimestamp();
			new Promise(next => {
				if (!this.canBeWritten()) {
					throw "forbidden";
				}
				if (this.roomExists) {
					let roomUpdate = { ...modelUpdateRoom };
					roomUpdate.lastUpdated = { ...servertTimeStamp };
					roomUpdate[this.userType + "NewMessage"] = true;
					this.db.room
						.update(roomUpdate, { merge: true })
						.then(next(this.db.room.collection("messages")));
				} else {
					let room = { ...modelNewRoom };
					room.members.client = parseInt(this.userId);
					room.members.driver = parseInt(
						this.transportService.driver.id
					);
					(room.room = this.roomName),
						(room.createdAt = { ...servertTimeStamp });
					room.lastUpdated = { ...servertTimeStamp };
					room[this.userType + "NewMessage"] = true;
					this.db.room.set(room, { merge: true }).then(() => {
						next(this.db.room.collection("messages"));
					});
				}
			})
				.then(messagesRef => {
					let message = messagesRef.doc();
					let model = { ...modelForMessageToSend };
					model.id = message.id;
					model.userType = this.userType;
					model.userId = this.userId;
					model.content = messageNode.content;
					model.createdAt = servertTimeStamp;
					return message.set(model);
				})
				.then(() => {
					this.takeMessagesVisibles();
					this.sendPushNotification(messageNode); //to driver
				})
				.catch(err => {
					alert(err);
				});
		},
		updateMessage(snapshot) {
			try {
				let messageData = snapshot.doc.data();
				let metadata = snapshot.doc.metadata;
				if (metadata.hasPendingWrites === false) {
					let messageId = messageData.id;
					let totalItems = this.messagesForChat.length ?? 0;
					for (let _i = totalItems - 1; _i >= 0; _i--) {
						if (this.messagesForChat[_i]._id == messageId) {
							let newMessage = this.fillModelMessage(
								snapshot.doc
							);
							Object.assign(this.messagesForChat[_i], newMessage);
							return;
						}
					}
				}
			} catch (err) {
				console.log("error catching change: " + err);
			}
		},
		fillModelMessage(data) {
			let source =
				data.metadata.hasPendingWrites ?? true ? "local" : "server";
			let item = data.data();
			let model = { ...modelForMessage };
			model.senderId = item.userType + "-" + item.userId;
			model.content = item.content;
			model._id = data.id;
			let createdAt =
				item.createdAt === null ? dayjs() : item.createdAt.toDate();
			model.date = dayjs(createdAt)
				.locale(this.lang)
				.format(this.formats.date);
			model.timestamp = dayjs(createdAt)
				.locale(this.lang)
				.format(this.formats.time);
			model.seen = model.senderId != this.currentSenderId;
			if (source === "local") {
				model.saved = false;
			} else {
				model.saved = true;
			}
			return model;
		},

		initChatRoom() {
			this.me = auth.currentUser.uid;
			firestore
				.collection("chats")
				.doc(this.roomName)
				.get()
				.then(_doc => {
					//obtenemos la referencia del room
					this.roomExists = _doc.exists;
					if (!_doc.exists) {
						throw new Error("Room no exist");
					}
					this.db.room = firestore
						.collection("chats")
						.doc(this.roomName);
				})
				.then(() => {
					this.db.room.update(
						{ driverNewMessage: false },
						{ merge: true }
					); //seen by client
					return new Promise(next => {
						this.listener = this.db.room
							.collection("messages")
							.orderBy("createdAt", "asc")
							.onSnapshot(
								{ includeMetadataChanges: true },
								doc => {
									doc.docChanges({
										source: "server"
									}).forEach(change => {
										if (change.type === "added") {
											let messageFetched = this.fillModelMessage(
												change.doc
											);
											this.messages.push(messageFetched);
										}
										if (change.type === "modified") {
											//se detecta cuando se escribe en back
											this.updateMessage(change);
										}
										this.db.room.update(
											{ driverNewMessage: false },
											{ merge: true }
										); //seen by client
										this.takeMessagesVisibles();
									});
									next();
								}
							);
					});
				})
				.then(() => {
					this.messagesLoaded = true;
					this.getDriverToken();
				})
				.catch(err => {
					console.log(err);
					this.back();
				})
				.finally(() => {
					this.loaded = true;
					this.takeMessagesVisibles();
				});
		}
	},
	mounted() {
		if (this.transportService.id > 0 && auth.currentUser) {
			this.currentService = this.transportService.id;
			this.initChatRoom();
		} else {
			this.back();
		}
		//let el = this.$refs["chat"].$el.getElementsByClassName("vac-textarea")[0];
		let el = this.$refs["chat"].$el.getElementsByClassName(
			"vac-container-scroll"
		)[0];
		this.referenceContainer = el;
		el.addEventListener("scroll", () => {
			if (this.messagesLoaded) {
				if (el.scrollTop <= 0) {
					this.messagesForChat = [...this.messages];
				}
			}
			//alert("scroll")
		});
	},
	computed: {
		...mapState("lang", ["lang"]),
		driverFullName() {
			return (
				this.transportService.driver.firstName +
				" " +
				this.transportService.driver.lastName
			);
		},
		showFooter() {
			//console.log(this.loaded && Boolean(this.me??"" == ""))
			return this.loaded && this.me != "";
		},
		userId() {
			return this.transportService.reservation.client.id;
		},
		myself() {
			return this.transportService.reservation.client;
		},
		currentSenderId() {
			return this.userType + "-" + this.userId;
		}
	},

	destroyed: function() {
		if (this.listener) {
			this.listener(); //desvincular agente de escucha
		}
		if (this.listenerForToken) {
			this.listenerForToken();
		}
	}
};
</script>
<style scoped>
i {
	border: solid whitesmoke;
	border-width: 0 5px 5px 0;
	display: inline-block;
	padding: 3px;
}
.leftArrow {
	transform: rotate(135deg);
}

.vac-box-footer {
	z-index: 4;
	position: fixed !important;
	bottom: 0;
	width: 100%;
}
::v-deep .vac-room-footer {
	display: block !important;
}
.vac-app-border-b {
	height: 50px !important;
	z-index: 4;
	position: fixed !important;
	top: 0;
}
.vac-messages-container {
	margin-bottom: 50px !important;
}

.chat-powered-by{
  text-align: center;
  background-color: #4F39CE;
  color: whitesmoke;
  font-weight: 600;
  height: 30px;
  padding: 5px;
}
.vac-card-window {
      height: calc(100vh - (40px + 30px)) !important;
      /*height: 90vh !important;*/
    }
</style>

<style>
@media only screen and (max-width: 768px) {
    .vac-box-footer {
      z-index: 4;
      position: fixed !important;
      bottom: 0;
      width: 100%;
    }

	.vac-messages-container {
		margin-bottom: 100px !important;
	}
}
</style>


