<template>
  <v-container class="text-center pb-0 mb-0" v-if="products.length > 0">
    <div class="mb-0 title font-weight-bold">
      {{ t("title") }}
    </div>
    <div v-if="ready">
      <v-tabs fixed-tabs v-model="tab">
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          v-if="productTours.length"
          :ripple="false"
          :class="tab === 'tab-1' ? 'black--text' : 'grey--text'"
          href="#tab-1"
        >
          <span class="font-weight-bold text-none">{{t('tour')}}</span>
        </v-tab>
        <v-tab
          v-if="productAdditional.length"
          :ripple="false"
          :class="tab === 'tab-2' ? 'black--text' : 'grey--text'"
          href="#tab-2"
        >
          <span class="font-weight-bold text-none">{{t('additional')}}</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" :touchless="true">
        <v-tab-item
          key="1"
          v-if="tab === 'tab-1'"
          value="tab-1"
        >
          <hooper
            class="mt-4"
            :items-to-show="$vuetify.breakpoint.mdAndUp ? 3 : 2"
            :settings="hooperSettings"
          >
            <slide
              v-for="(item, i) of productTours"
              :key="i"
              class="py-3 px-2"
            >
              <ProductCard
                :item="item"
                :title="item.content.name"
                @click="openProduct(item)"
              />
            </slide>
            <hooper-navigation v-if="$vuetify.breakpoint.mdAndUp" slot="hooper-addons"></hooper-navigation>
          </hooper>
        </v-tab-item>
        <v-tab-item
          key="2"
          v-if="tab === 'tab-2'"
          value="tab-2"
        >
          <hooper
            class="mt-4"
            :items-to-show="$vuetify.breakpoint.mdAndUp ? 3 : 2"
            :settings="hooperSettings"
          >
            <slide
              v-for="(item, i) of productAdditional"
              :key="i"
              class="py-3 px-2"
            >
              <ProductCard
                :item="item"
                :title="item.content.name"
                @click="openProduct(item)"
              />
            </slide>
            <hooper-navigation v-if="$vuetify.breakpoint.mdAndUp" slot="hooper-addons"></hooper-navigation>
          </hooper>
        </v-tab-item>
      </v-tabs-items>
    </div>

    <v-btn
      v-if="products.length > 3"
      color="primary"
      text
      @click="openAll()"
      class="text-none text-decoration-underline font-weight-bold mt-3"
      >{{ t("seeMore") }}</v-btn
    >
    <v-dialog v-model="dialogAllProducts" max-width="750px">
      <v-card class="rounded-lg">
        <BtnClose @click="dialogAllProducts = false" />
        <v-container class="text-center px-1" v-if="dialogAllProducts">
          <div class=" mb-3 title font-weight-bold">
            {{ t("title") }}
          </div>
          <v-tabs class="py" fixed-tabs v-model="tab">
            <v-tabs-slider></v-tabs-slider>
            <v-tab
              v-if="productTours.length"
              :ripple="false"
              :class="tab === 'tab-1' ? 'black--text' : 'grey--text'"
              href="#tab-1"
            >
              <span class="font-weight-bold text-none">{{t('tour')}}</span>
            </v-tab>
            <v-tab
              v-if="productAdditional.length"
              :ripple="false"
              :class="tab === 'tab-2' ? 'black--text' : 'grey--text'"
              href="#tab-2"
            >
              <span class="font-weight-bold text-none">{{t('additional')}}</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" :touchless="true">
            <v-tab-item
              key="1"
              v-if="tab === 'tab-1'"
              value="tab-1"
            >
              <v-row dense class="mx-2 py-3 mt-2">
                <v-col cols="6" class="pa-2" md="4" v-for="(item, i) of productTours" :key="i">
                  <ProductCard
                    :item="item"
                    :title="item.content.name"
                    @click="openProduct(item)"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item
              key="2"
              v-if="tab === 'tab-2'"
              value="tab-2"
            >
              <v-row dense class="mx-2 py-3 mt-2">
                <v-col cols="6" class="pa-2" md="4" v-for="(item, i) of productAdditional" :key="i">
                  <ProductCard
                    :item="item"
                    :title="item.content.name"
                    @click="openProduct(item)"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import BtnClose from "@/components/btn/BtnClose";
import ProductCard from "./ProductCard";
import { mapState, mapActions } from "vuex";
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
// import { getPath } from "@/helpers/utils";
import "hooper/dist/hooper.css";
export default {
  components: {
    HooperNavigation,
    BtnClose,
    ProductCard,
    Hooper,
    Slide
  },
  data: () => ({
    tab: null,
    dialogAllProducts: false,
    ready: false,
    hooperSettings: {
      centerMode: false,
      wheelControl: false,
      mouseDrag: false,
      initialSlide: 0
    }
  }),
  computed: {
    ...mapState("market", ["products"]),
    lang: vm => vm.$store.state.lang.lang,
    productTours() {
      return this.products.filter((item) => item.type === 'activity')
    },
    productAdditional() {
      return this.products.filter((item) => item.type !== 'activity')
    }
  },
  watch: {
    lang() {
      this._fetchProducts();
    }
  },
  mounted() {
    this.ready = true
    if(typeof this.$route.query.showExtras != 'undefined') this.dialogAllProducts = true
  },
  methods: {
    ...mapActions("market", ["_fetchProducts"]),
    openAll() {
      this.dialogAllProducts = !this.dialogAllProducts
    },
    openProduct(item) {
      if (window.location.host === "ride.shuttlecentral.com") {
        // Esto se usa para hacer tracking en Google Analytics
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "selectProduct",
          selectProduct: item.name
        });
        //
      }

      if (item.settingVars.externalSaleUrl!="") {

        window.open(item.settingVars.externalSaleUrl, '_blank');

      }else{

        this.$router.push(`/market/${item.id}`);

      }

    },

    t(key, options = {}) {
      return this.$t(`market.product.${key}`, options);
    }
  }
};
</script>
<style lang="scss" scoped>
.hooper {
  height: auto;
}
::v-deep .hooper-navigation {
    button {
      display: block;
      background-color: #fff;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      padding: 0.2em;
      box-shadow: 0px 4px 8px rgba(2, 29, 62, 0.1);
      svg {
        fill: #4F39CF
      }
    }
  }
::v-deep .v-dialog {
  margin: 15px;
}
::v-deep .v-tab--active:hover::before {
  opacity: 0 !important;
}
::v-deep .v-tab:before {
  background-color: none !important;
}
</style>
