<template>
	<div id="translateWidget" class="translateWidget" ref="translateWidget" >
	</div>
</template>

<script>
export default {
	data() {
		return {
			translator:null,
			elementScript:null
		}
	},
	mounted() {
			let el = document.getElementById("ScTranslator");
			if(el !== null){
				this.restoreWidget()
				return;
			}
			// Load the Google Translate widget
			this.elementScript       = document.createElement('script');
			this.elementScript.id    = 'ScTranslator';
			this.elementScript.type  = 'text/javascript';
			this.elementScript.src   = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
			this.elementScript.async = true;
			this.elementScript.defer = true;
			document.body.appendChild(this.elementScript);
			// Initialize the Google Translate widget
			window.googleTranslateElementInit = this.initTranslateWidget;

	},
	beforeDestroy(){
		this.saveWidget();
		this.resetTranslation();
	},
	methods: {
		saveWidget(){
			let el = document.createElement('div');
			el.id="saved-translator-widget"
			el.appendChild(this.$refs["translateWidget"].querySelector(".goog-te-gadget"));
			document.body.appendChild(el);
		},
		restoreWidget() {
			// Find the saved <div> containing the Google Translate widget
			const savedWidget = document.querySelector('#saved-translator-widget');
			if (!savedWidget) {
				console.error("Saved translateWidget element not found.");
				return; // Exit method if saved translateWidget element is not found
			}

			// Append the saved <div> back to its original position (assuming it was saved with this class)
			const originalContainer = this.$refs["translateWidget"]
			if (originalContainer) {
				originalContainer.appendChild(savedWidget);
			} else {
				console.error("Original translateWidget container not found.");
			}
		},
		initTranslateWidget() {
			const parameters = {
        autoDisplay:true,
				pageLanguage: '',
				includedLanguages:'',
				layout: window.google.translate.TranslateElement.InlineLayout.HORIZONTAL
			};
			// You can customize the options of the widget here if needed
			this.translator = new window.google.translate.TranslateElement(parameters, 'translateWidget');
		},
		resetTranslation() {
			try{
				let el = document.querySelector("iframe.skiptranslate");
				if(el){
					el.contentDocument.getElementById(":1.restore").click();
				}
			}catch(err){
				console.log(err)
			}

        }
	},
	computed:{

	}

};
</script>

<style>
body{
	top: unset !important;
}
iframe.skiptranslate {
	position: absolute;
	display: none !important;
	height: 0px;
}
#goog-gt-tt{
	display: none !important;
}


.goog-te-combo{
  /* Style the select element */
  text-decoration: underline;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  font-size: 15px !important;
  font-weight: 300;
  color: blue ;
  cursor: pointer;
}
</style>
<style scoped>

.translateWidget{
	height: 40px;
    padding: 5px;
    display: flex;
    justify-content: center;
}
main{
	padding-top:unset !important ;
}



</style>
