<template>
	<keep-alive>
		<router-view class="white " />
	</keep-alive>
</template>
<script>
import { mapActions } from "vuex";
export default {
	methods: {
		...mapActions("global", ["_hideIntercomLauncher"])
	},
	mounted() {
		/*this._hideIntercomLauncher();*/
	}
};
</script>
<style lang="scss" scoped>
.w100 {
  width: 100%;
}
</style>
