<template>
	<div class="fill-height d-flex flex-column white">
		<AppBar
			:to-page="toPage"
			color="primary"
			dark
			disable-help
			disable-lang
			:disable-menu="false"
		/>
		<keep-alive>
			<router-view />
		</keep-alive>
	</div>
</template>
<script>
import AppBar from "@/components/AppBar";
import { mapActions } from "vuex";
export default {
	components: {
		AppBar
	},
	computed: {
		toPage: vm => vm.$store.state.backRoute
	},
	methods: {
		...mapActions("global", ["_hideIntercomLauncher"])
	},
	mounted() {
		//this._hideIntercomLauncher();
	}
};
</script>
