<template>
  <div class="fill-height d-flex flex-column white">
    <AppBar
      color="primary"
      dark
      disable-help
      disable-lang
      :disable-menu="false"
    />
    <v-app-bar dense color="primary40" prominent flat>
      <v-card-text
        class="flex text-center font-weight-medium"
        v-html="$t('global.traveler.welcome')"
      >
      </v-card-text>
    </v-app-bar>
    <v-app-bar prominent dense color="primary40" flat style="z-index: 4">
      <v-container>
        <div
          class="subtitle-2 font-weight-thin flex text-center"
          v-html="$t('global.infoTitle')"
        ></div>
        <div class="flex text-center">
          <v-btn
            class="font-weight-bold mb-n1 mx-1 px-1"
            text
            color="primary"
            @click="dialog.covid = !dialog.covid"
            >{{ $t("global.btnCovid") }}</v-btn
          >
          <v-btn
            class="font-weight-bold mx-1 px-1"
            text
            color="primary"
            @click="dialog.arrival = !dialog.arrival"
            >{{ $t("global.btnArrival") }}</v-btn
          >
          <v-btn
            v-if="isIataCun"
            class="font-weight-bold mx-1 px-1"
            text
            color="primary"
            @click="dialog.info = !dialog.info"
            >Cancun</v-btn
          >
        </div>
      </v-container>
    </v-app-bar>
    <ProductList />
    <div class="fill-height">
        <v-container style="position: relative;">
          <TransportServices
            v-for="(service, i) in transportServices"
            :key="i"
            :service="service"
            :darkStyle="darkStyle(i)"
          />
          <v-img class="mt-7" src="~@/assets/lines.svg"></v-img>
          <v-row
            dense
            class="my-7"
            v-if="reservationModel.additionalServices.length > 0"
          >
            <v-col cols="12">
              <LayoutBase
                text-color="black--text"
                color="secondary"
                :title="t('traveler.additional-services.title').toUpperCase()"
              >
                <LayoutCard
                  class="mb-2"
                  v-for="(additional, i) of reservationModel.additionalServices"
                  :key="i"
                  icon="hail"
                  :title="additional.additionalServiceType.name"
                >
                  <template v-slot:icon>
                    <v-container class="ma-0" style="width: 50px">
                      <v-img
                        max-height="auto"
                        max-width="45px"
                        width="100%"
                        :src="getIcon(additional.additionalServiceType.code)"
                      ></v-img>
                    </v-container>
                  </template>
                  <div class="d-flex justify-space-between">
                    <span>{{
                      t(
                        `traveler.additional-services.description.${additional.additionalServiceType.rateType}.subtitle`,
                        { cant: sumQuantity(additional) }
                      )
                    }}</span>
                    <span class="subtitle-2 font-weight-bold"
                      >{{ additional.bookingCurrency.toUpperCase() }} ${{
                        additional.bookingPriceTotal
                      }}</span
                    >
                  </div>
                </LayoutCard>
              </LayoutBase>
              <v-img class="mt-7" src="~@/assets/lines.svg"></v-img>
            </v-col>
          </v-row>
          <div class="d-md-none">
            <GordianBanner
              step="check-in"
              display-type="default-banner"
            />
          </div>
          <v-form ref="form">
            <v-row dense class="my-7">
              <v-col cols="12">
                <LayoutBase :title="t('contact.header').toUpperCase()">
                </LayoutBase>
              </v-col>
              <v-col cols="12" class="mt-5">
                <TextField
                  :rules="emailRules"
                  v-model="formContact.email"
                  :title="`${t('contact.email')}*`"
                />
              </v-col>
              <v-col cols="12">
                <PhoneField
                  v-if="copied"
                  ref="phoneField"
                  v-model="formContact.phone"
                  :messages="t('contact.phoneMessage')"
                  :title="`${t('contact.phone')}*`"
                />
              </v-col>
            </v-row>
          </v-form>

          <div class="gordian-siderbar d-md-block">
            <GordianBanner
              step="check-in"
              display-type="vertical-banner"
            />
          </div>

        </v-container>

      <v-card height="88px" class="mt-1" elevation="7">
        <v-container class="d-flex align-center fill-height">
          <v-btn block rounded outlined color="primary" @click="save">{{
            $t("global.btnSaveContacto")
          }}</v-btn>
        </v-container>
      </v-card>
    </div>
    <DialogArrival v-model="dialog.arrival" />
    <DialogCovid v-model="dialog.covid" />
    <DialogCancun v-model="dialog.info" />
  </div>
</template>

<script>
import AppBar from "@/components/AppBar";
import DialogArrival from "@/components/dialog/DialogArrival";
import GordianBanner from "@/components/GordianBanner";
import DialogCovid from "@/components/dialog/DialogCovid";
import DialogCancun from "@/components/dialog/DialogCancun";
import LayoutBase from "@/layouts/LayoutBase";
import LayoutCard from "@/layouts/LayoutCard";
import TextField from "@/components/field/TextField";
import PhoneField from "@/components/field/PhoneField";
import { getIcon } from "@/helpers/market";
import ProductList from "@/components/market/ProductList";
import TransportServices from "./TransportServices";
import { api } from "@/service/api.service";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    AppBar,
    ProductList,
    DialogCancun,
    DialogArrival,
    DialogCovid,
    LayoutCard,
    LayoutBase,
    TextField,
    PhoneField,
    TransportServices,
    GordianBanner
  },
  data: vm => ({
    emailRules: [
      v => !!v || vm.$t("global.required"),
      v =>
        !v ||
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(v) ||
        vm.$t("global.emailValid")
    ],
    dialog: {
      info: false,
      arrival: false,
      covid: false
    },
    formContact: {
      phone: null,
      email: null
    },
    copied: false,
    destinationAirportIata:null,
    originAirportIata:null,
  }),
  created() {
    this.setContactData();
  },
  async mounted() {
    await this._fetchReservation();
    this._initIntercom();
    //this._hideIntercomLauncher();
    this._initGrx();
  },
  watch: {
    reservationId() {
      this.setContactData();
      // Si tiene un servicio en CUN se muestra alerta de trafico
      if (this.isIataCun) {
        this.dialog.info = true
      }
    }
  },
  computed: {
    ...mapState("reservation", ["reservationModel"]),
    ...mapState('lang',['lang']),
    reservationId: vm => vm.reservationModel.id,
    transportServices: vm => vm.reservationModel?.transportServices || [],
    isIataCun: vm => {
      let aux = false
      vm.transportServices.forEach(({dropoff, pickup}) => {
        if(dropoff.iata === 'CUN') {
          aux = true
        } else if (pickup.iata === 'CUN') {
          aux = true
        }
      })
      return aux
    }
  },
  methods: {
    ...mapActions("service", ["_openCheckin"]),
    ...mapActions("reservation", ["_fetchReservation"]),
    ...mapActions("global", ["_initIntercom", "_hideIntercomLauncher"]),
    loadAirportIatas(){
      if (this.transportServices.length>0) {
        let {pickup, dropoff, flightValidationData} = this.transportServices[0]

        if (dropoff.isAirport) { //salida, es decir, vuelo origen es el iata del aeropuerto
          this.originAirportIata = dropoff.iata
          if (flightValidationData != null) {
            this.destinationAirportIata = flightValidationData.arrival.airportIata
          }
        }else{
          this.destinationAirportIata = pickup.iata
          if (flightValidationData != null) {
            this.originAirportIata = flightValidationData.departure.airportIata
          }
        }

      }
    },
    _initGrx(){
      this.loadAirportIatas()
      window.Grx.setContext({
        env: "prod",
        lang: this.lang == 'es' ? 'es-mx' : 'en-us',
        currency: this.lang == 'es' ? 'mxn' : 'usd',
        publishable_key: "pk_cDcPr20LoO0EXwcq", // necesario para la producción
      });
      window.Grx.set({
        passengerCount: this.reservationModel.adults + this.reservationModel.children,
        childCount: this.reservationModel.children,
        adultCount: this.reservationModel.adults,
        originAirport: this.originAirportIata,
        destinationAirport: this.destinationAirportIata,
        // airline: 'EE'
      })
    },
    darkStyle(index) {
      if (index % 2 === 0) return false;
      return true;
    },
    getIcon(code) {
      return getIcon(code, true);
    },
    sumQuantity(additional) {
      if (additional.rateType === "person") {
        const { children, adults, infants } = additional;
        return children + adults + infants;
      } else {
        return additional.quantity;
      }
    },
    setContactData() {
      this.copied = false;
      this.formContact.email = this.reservationModel?.client?.email;
      this.formContact.phone = this.reservationModel?.client?.phone;
      setTimeout(() => (this.copied = true), 500);
    },
    async save() {
      if (!this.$refs.form.validate()) return;
      await api
        .patch("/travelers/clients/" + this.reservationModel.client.id, {
          data: { ...this.formContact }
        })
        .then(() =>
          this.$root.show({
            type: "success",
            title: this.$t("global.message.dataSaved"),
            description: this.$t("global.message.saved")
          })
        )
        .catch(e => console.log(e));
    },
    t(key, options = {}) {
      return this.$t(`section.${key}`, options);
    }
  }
};
</script>
<style>
  .gordian-siderbar {
    position: absolute;
    left: 103%;
    top: 0;
    width: 300px;
    display: none;
  }
</style>
<style lang="scss" scoped>
.img-banner-promotion {
  cursor: pointer;
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}
</style>
