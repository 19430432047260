<template>
	<div class="fill-height white">
		<div v-if="showChat" class="fill-height">
			<Chat
				:roomName="roomName"
				:transportService="checkinModel"
				@back="showChat = false"
			/>
		</div>
		<div
			v-else
			class="fill-height d-flex flex-column align-center"
			:style="{ backgroundColor: '#4F39CF' }"
		>
			<AppBar :color="config.color" dark />
			<v-app-bar flat :color="config.color" prominent></v-app-bar>

			<v-card
				v-if="currentStep.status != 'pending'"
				width="93%"
				elevation="1"
				max-width="700px"
				height="12rem"
				class="rounded-t-lg header-card "
			>
				<v-toolbar flat dense>
					<v-toolbar-items
						style="width:100%"
						class="d-flex flex-row align-center justify-space-between"
					>
						<div class="primary--text body-2 font-weight-bold">
							{{ $t("global.hello", { name: clientName }) }}
						</div>
						<div class="d-flex flex-row align-center">
							<div class="body-2 mr-3">
								{{ $t("global.tracking.service") }}
							</div>
							<div class="body-2 primary--text font-weight-bold">
								#{{ serviceId }}
							</div>
						</div>
					</v-toolbar-items>
				</v-toolbar>
				<v-container
					class="ma-0 py-0 d-flex justify-center"
					v-if="currentStep.image != undefined"
				>
					<v-img
						class="ma-0 pa-0"
						width="100%"
						max-width="300px"
						height="auto"
						:src="currentStep.image"
					></v-img>
				</v-container>
				<v-toolbar flat dark dense color="primary" height="35">
					<v-toolbar-title
						class="font-weight-black flex text-center"
						>{{ currentStep.title }}</v-toolbar-title
					>
				</v-toolbar>
			</v-card>
			<v-card
				v-else
				width="93%"
				elevation="1"
				max-width="700px"
				height="9rem"
				class="rounded-lg header-card d-flex flex-row"
			>
				<v-img
					class="ml-3"
					width="40%"
					max-width="200px"
					height="auto"
					src="~@/assets/endIlustration.png"
				></v-img>
				<v-container
					class="d-flex flex-column justify-center align-center justify-space-around"
				>
					<div class="primary--text text-h6 font-weight-bold">
						{{ $t("global.hello", { name: clientName }) }}
					</div>
					<div class="body-2">
						{{ $t("global.tracking.service") }}
					</div>
					<div class="primary--text font-weight-bold">
						#{{ serviceId }}
					</div>
				</v-container>
			</v-card>
			<v-card width="98%" height="100%" class="rounded-t-lg">
				<div class="mt-16" v-if="currentStep.status != 'pending'">
					<br />
				</div>
				<v-container class="mt-15 d-flex flex-row justify-space-around">
					<v-btn
						class="font-weight-bold"
						text
						color="primary"
						@click="openArrival()"
						>{{ $t("global.btnArrival") }}</v-btn
					>
					<v-btn
						class="font-weight-bold mb-n1"
						text
						color="primary"
						@click="openArrival()"
						>{{ $t("global.btnCovid") }}</v-btn
					>
				</v-container>

				<v-container class="d-flex flex-column align-center">
					<div class="title primary--text font-weight-bold mb-5">
						{{ tSection("pickupDropOff.title") }}
					</div>

					<LayoutCard
						elevation="0"
						icon="hail"
						:title="tSection('pickupDropOff.form.pickup.location')"
						>{{ pickupfullName }}</LayoutCard
					>
					<LayoutCard
						elevation="0"
						class="mt-3"
						icon="location_on"
						:title="tSection('pickupDropOff.form.dropOff.location')"
						>{{ dropofffullName }}</LayoutCard
					>
					<v-row style="width:100%" class="my-2" v-if="appMode !='development'">
						<v-col cols="12" class="mx-0 px-0">
							<MapDirection
						/></v-col>
					</v-row>

					<div class="title primary--text font-weight-bold mb-5">
						{{ tSection("flight.title") }}
					</div>
					<v-card color="primary100" width="100%">
            <LayoutFlight
              elevation="0"
              color="primary100"
              icon-color="primary"
              :title="tSection('flight.form.flightNumber')"
              :flightNumber="`${checkinModel.flightNumber} ${airline}`"
              :flightTime="checkinModel.flightDatetime"
            />
					</v-card>

					<div class="title primary--text font-weight-bold my-5">
						{{ tSection("schedule.title") }}
					</div>

					<LayoutDateTime
						elevation="0"
						color="primary100"
						icon-color="primary"
						:pickupTime="checkinModel.pickupDatetime"
            :pickupTimeLimit="checkinModel.pickupDatetimeLimit"
						:dateTitle="tSection('schedule.pickupDate')"
						:timeTitle="tSection('schedule.pickupTime')"
					/>

					<div class="title primary--text font-weight-bold my-5">
						{{ tSection("passenger.passenger") }}
					</div>
					<CardTransferType
						hidde-transport
						elevation="0"
						confirm-mode
					/>
					<v-card
						elevation="0"
						width="100%"
						height="20rem"
						class="d-flex justify-center align-center"
					>
						<qrCodeVue :value="qrValue" size="200" level="H" />
					</v-card>
				</v-container>
			</v-card>
			<v-footer height="72px" app color="white" class="rounded-t-lg">
				<div
					class="avatar-name d-flex flex-column align-center"
					v-if="hasDriver"
				>
					<v-avatar size="64"> <img :src="driverImage"/></v-avatar>
					<div class="mt-1 body-2 font-weight-bold">
						{{ driverName }}
					</div>
				</div>
				<div
					class="avatar-car d-flex flex-column align-center"
					v-if="hasDriver"
				>
					<v-avatar size="64"> <img :src="transportImage"/></v-avatar>
					<div class="mt-1 body-2 font-weight-bold">
						{{ transportName }}
					</div>
				</div>
			</v-footer>
			<v-btn
				rounded
				width="64px"
				height="64px"
				class="chat-btn rounded-circle"
				color="secondary"
				@click="clickedChat"
			>
				<v-badge
					color="#28a745"
					class="mt-1"
					offset-x="10"
					offset-y="12"
					:value="newMessages"
          :class="{ __shake: newMessages }"
					content="1"
				>
					<v-icon size="30" color="white pa-0 ma-0">message</v-icon>
				</v-badge>
			</v-btn>
			<DialogArrival v-model="dialog.arrival" />
			<DialogCovid v-model="dialog.covid" />
		</div>

	</div>
</template>

<script>
import AppBar from "@/components/AppBar";
import LayoutCard from "@/layouts/LayoutCard";
import LayoutDateTime from "@/layouts/LayoutDateTime";
import LayoutFlight from "@/layouts/LayoutFlight";
import MapDirection from "@/components/map/MapDirection";
import qrCodeVue from "qrcode.vue";
import CardTransferType from "@/components/card/CardTransferType";
import Chat from "@/components/Chat";
import pusher from "@/plugins/pusher.js";
import { mapFields } from "@/helpers/mapFields";
import { mapActions, mapGetters, mapState } from "vuex";
import { api } from "@/service/api.service";
import { newRoomModel } from "@/helpers/constants";
import { getPath } from "@/helpers/utils";
import { auth, firestore, firebase, messaging } from "@/plugins/firebase.js";
import { PushNotifications } from "@capacitor/push-notifications";
import { FCM } from "@capacitor-community/fcm";
export default {
	components: {
		Chat,
		AppBar,
		DialogArrival: () => import("@/components/dialog/DialogArrival"),
		DialogCovid: () => import("@/components/dialog/DialogCovid"),
		qrCodeVue,
		MapDirection,
		LayoutDateTime,
    LayoutFlight,
		CardTransferType,
		LayoutCard
	},
	data: () => ({
		config: {
			color: "primary"
		},
		gifAuto: require("../../assets/auto.gif"),
		gifLocation: require("../../assets/llegada.gif"),
		gifEnd: require("../../assets/final.gif"),
		dialog: {
			arrival: false,
			covid: false
		},
		showChat: false,
		newMessages: false,
		roomChatExist: false
	}),
	async mounted() {
		this.init();
	},
	watch: {
		$route(to) {
			if (to.name == "Tracking") {
				if (to.query?.chat == "true") {
					this.init();
				}
			}
		},
		checkinModel() {
			this.init();
		}
	},
	computed: {
		...mapState("service", ["checkinModel"]),
		...mapGetters("service", ["isArrival"]),
		...mapState("global", ["intercomRunning", "platform"]),
		...mapFields({
			fields: ["trackingStatus"],
			base: "checkinModel",
			mutation: "SET_UPDATE_MODEL",
			module: "service"
		}),

		qrValue: vm =>
			vm.checkinModel.id > 0
				? `${process.env.VUE_APP_BASE_URL}/#/booking/${vm.checkinModel.id}`
				: "",
		clientName: vm =>
			vm.checkinModel?.reservation?.client?.firstName?.split(" ")[0] ||
			"",

		serviceId: vm => vm.checkinModel?.serviceNumber,

		airline: vm => vm.checkinModel.flightValidationData?.airline || "",

		pickupfullName: vm => vm.checkinModel.pickup?.fullName || "",

		dropofffullName: vm => vm.checkinModel.dropoff?.fullName || "",

		flightDateTitle: vm =>
			vm.isArrival
				? "flight.form.arrivalDate"
				: "flight.form.takeoffDate",

		flightTimeTitle: vm =>
			vm.isArrival
				? "flight.form.arrivalTime"
				: "flight.form.takeoffTime",

		//Tracking Computeds
		roomName: vm => String(vm.checkinModel.id),
		driver: vm => vm.checkinModel.driver || {},
		driverName: vm =>
			`${vm.driver?.firstName} ${vm.driver?.lastName}`.substring(0, 12),
		driverImage: vm => vm.driver.image ?? "",
		transportImage: vm => vm.checkinModel?.transportUnit?.image ?? "",
		transportName: vm => vm.checkinModel?.transportUnit?.name ?? "",

		hasDriver: vm => vm.driver.id > 0,
		currentStep() {
			return (
				this.steps.find(
					step => step.status === this.checkinModel.trackingStatus
				) ?? {}
			);
		},
		canEnableProcessChat: vm =>
			vm.checkinModel.reservation.client.id > 0 && vm.hasDriver,
		canChatWithDriver() {
			let statusActual = this.checkinModel.trackingStatus;
			let granted = "onWayToPickup";
			let indexActual = this.steps.findIndex(_el => {
				return _el.status === statusActual;
			});
			let indexGranted = this.steps.findIndex(_el => {
				return _el.status === granted;
			});
			let flagStep = indexActual >= indexGranted;
			return flagStep && this.hasDriver && this.roomChatExist;
		},
		steps() {
			if (this.checkinModel.id < 0) return [];
			return [
				{
					status: "pending",
					image: null,
					title: this.tTrack("trackingStatus.pending"),
					info: this.tTrack("trackingStatusInfo.pending")
				},
				{
					status: "onWayToPickup",
					image: this.gifAuto,
					title: this.tTrack("trackingStatus.onWayToPickup"),
					info: this.tTrack("trackingStatusInfo.onWayToPickup", {
						pickupName: this.checkinModel.pickup.name
					})
				},
				{
					status: "atPickup",
					image: this.gifLocation,
					title: this.tTrack("trackingStatus.atPickup"),
					info: this.tTrack("trackingStatusInfo.atPickup", {
						pickupName: this.checkinModel.pickup.name
					})
				},
				{
					status: "onWayToDropoff",
					image: this.gifAuto,
					title: this.tTrack("trackingStatus.onWayToDropoff"),
					info: this.tTrack("trackingStatusInfo.onWayToDropoff", {
						dropoffName: this.checkinModel.dropoff.name
					})
				},
				{
					status: "finished",
					image: this.gifEnd,
					title: this.tTrack("trackingStatus.finished"),
					info: this.tTrack("trackingStatusInfo.finished")
				},
				{
					status: "noShow",
					image: this.gifLocation,
					title: this.tTrack("trackingStatus.noShow"),
					info: this.tTrack("trackingStatusInfo.noShow")
				}
			];
		},
    appMode () {
      return process.env.NODE_ENV
    }
	},
	methods: {
		...mapActions("global", ["_initIntercom"]),
		...mapActions("global", ["_showIntercomLauncher"]),
    requestNotificationPermission(){
        if ('safari' in window && 'pushNotification' in window.safari) {
          var permissionData = window.safari.pushNotification.permission('YOUR_WEBSITE_PUSH_ID');
          if (permissionData.permission === 'default') {
              window.safari.pushNotification.requestPermission(
                  'YOUR_WEBSITE_PUSH_ID',
                  'https://yourwebsite.com/push-notification-handler',
                  { /* Optional user data */ },
                  function (permission) {
                      if (permission === 'denied') {
                          console.warn('Permission for push notifications denied');
                      } else if (permission === 'granted') {
                          console.log('Permission for push notifications granted');
                          // Subscribe the user for push notifications
                          window.safari.pushNotification.subscribe('YOUR_WEBSITE_PUSH_ID');
                      }
                  }
              );
          } else if (permissionData.permission === 'denied') {
              console.warn('Permission for push notifications denied');
          } else if (permissionData.permission === 'granted') {
              console.log('Permission for push notifications already granted');
              // Subscribe the user for push notifications
              window.safari.pushNotification.subscribe('YOUR_WEBSITE_PUSH_ID');
          }
      } else {
          console.warn('Push notifications not supported in this browser');
      }
    },
		openArrival() {
			this.dialog.arrival = true;
		},
		async init() {
			if (this.checkinModel.id == -1) return;

			try {
				if (this.canEnableProcessChat) {
					await this.observableAuth();
					const doc = await this.firebaseCollection();
					await this.updateFirebaseToken(doc);
				} else {
					this._initIntercom();
				}
			} catch (error) {
				console.log(error);
			} finally {
				if (this.$route.query?.chat == "true") {
					this.showChat = true;
				}
				this.initChannel();
			}
		},
		openCovid() {
			this.dialog.covid = true;
		},
		tSection(key, options = {}) {
			return this.$t(`section.${key}`, options);
		},
		tTrack(key, options = {}) {
			return this.$t(`section.tracking.${key}`, options);
		},
		listenForNewMessages(chatRoom) {
			chatRoom.ref.onSnapshot(doc => {
				if (doc.exists) this.newMessages = doc.data().driverNewMessage;
			});
		},
		initChannel() {
			let channel = pusher
				.getInstance()
				.subscribe(`private-tracking-${this.$route.params.serviceId}`);
			channel.bind("status-changed", data => {
				let trackingStatus =
					data["trackingStatus"] ?? this.checkinModel.trackingStatus;
				this.trackingStatus = trackingStatus;
				this.$root.show({
					title: this.tTrack("statusChange"),
					description: this.currentStep.info,
					btn: true
				});
			});
		},
		clickedChat() {
			if (this.intercomRunning) {
				this._showIntercomLauncher();
			} else {
				if (this.canChatWithDriver) {
					this.showChat = true;
				} else {
					this.$root.show({
						title: "Ups!",
						description: this.tTrack("chatNotAvailable"),
						btn: true
					});
				}
			}
		},
		async observableAuth() {
			return new Promise((success, reject) => {
				auth.onAuthStateChanged(user => {
					if (user) {
						success();
					} else {
						//no logueado
						api.get(
							"travelers/chat/auth/" +
								this.$route.params.serviceId,
							{}
						).then(resp => {
							let token = resp.data.token;
							auth.signInWithCustomToken(token)
								.then(() => {
									success();
								})
								.catch(err => {
									reject(err);
								});
						});
					}
				});
			});
		},
		async firebaseCollection() {
			const _doc = await firestore
				.collection("chats")
				.doc(this.roomName)
				.get();
			if (!_doc.exists) {
				const servertTimeStamp = firebase.firestore.FieldValue.serverTimestamp();
				let room = Object.assign({}, new newRoomModel());
				let client = { ...this.checkinModel.reservation.client };
				room.members.client = parseInt(
					this.checkinModel.reservation.client.id
				);
				room.members.driver = parseInt(this.driver.id);
				room.room = this.roomName;
				room.client.firstName = client.firstName;
				room.client.lastName = client.lastName;
				room.serviceNumber = this.checkinModel.serviceNumber;
				room.createdAt = { ...servertTimeStamp };
				room.lastUpdated = { ...servertTimeStamp };
				//cuando se cree el room se solicita el token
				return await _doc.ref.set(room, { merge: true }).then(() =>
					firestore
						.collection("chats")
						.doc(this.$route.params.serviceId)
						.get()
				);
			}
			return _doc;
		},
		async updateFirebaseToken(doc) {
			this.roomChatExist = doc.exists;
			this.listenForNewMessages(doc);
			if (doc.exists) {
				console.log(this.platform);
				if (this.platform == "web") {
					return await messaging
						.getToken({
							vapidKey: process.env.VUE_APP_FCM_VAPID
						})
						.then(fcmToken => {
							//actualizamos el token del cliente
							try {
								if (fcmToken) {
									doc.ref.update({ clientToken: fcmToken });
								} else {
									console.log(
										"No registration token available. Request permission to generate one."
									);
								}
								return;
							} catch (error) {
								console.log("Error generating token " + error);
								return;
							}
						});
				} else {
					console.log("PUSH NOTIFICATIONS RONY PARRA");
					return new Promise((resolve, reject) => {
						PushNotifications.requestPermissions().then(
							async permission => {
								if (permission.receive === "granted") {
									// Register with Apple / Google to receive push via APNS/FCM
									PushNotifications.register();
								} else {
									// No permission for push granted
									reject();
								}
							}
						);
						PushNotifications.addListener("registration", token => {
							// Enable the auto initialization of the library
							FCM.setAutoInit({ enabled: true });
							// Check the auto initialization status
							FCM.isAutoInitEnabled();
							FCM.getToken().then(fcmToken => {
								if (fcmToken) {
									doc.ref.update({
										clientToken: token.value
									});
									resolve();
								}
							});
						});
						PushNotifications.addListener(
							"registrationError",
							error => {
								console.log(
									"Error on registration: " +
										JSON.stringify(error)
								);
								reject();
							}
						);
						//var self = this;
						PushNotifications.addListener(
							"pushNotificationActionPerformed",
							event => {
								let url = "";
								try {
									url = event.notification.data.url;
									let { path } = getPath(url);
									if (path) {
										setTimeout(() => {
											this.$router.replace(path);
											this.showChat = true;
										}, 1500);
									}
								} catch (err) {
									console.log("err " + err);
								}
							}
						);
					});
				}
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.header-card {
	position: absolute;
	z-index: 4;
	top: 3%;
}
.avatar-name {
	width: 8rem;
	position: absolute;
	top: -45%;
	left: 15%;
}
.avatar-car {
	width: 8rem;
	position: absolute;
	top: -45%;
	right: 15%;
}
.chat-btn {
	z-index: 5;
	position: fixed;
	right: 3%;
	bottom: 4%;
}

	/* Animation property */
  .__shake {
    animation: wiggle 2s linear infinite;
}
/* Keyframes */
@keyframes wiggle {
    0%,
    7% {
        transform: rotateZ(0);
    }
    15% {
        transform: rotateZ(-15deg);
    }
    20% {
        transform: rotateZ(10deg);
    }
    25% {
        transform: rotateZ(-10deg);
    }
    30% {
        transform: rotateZ(6deg);
    }
    35% {
        transform: rotateZ(-4deg);
    }
    40%,
    100% {
        transform: rotateZ(0);
    }
}
</style>

